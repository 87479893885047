<script setup lang="ts">
import { ref } from 'vue';

const props = defineProps({
    disabled: {
        default: false,
        type: Boolean,
    },
    error: {
        default: false,
        type: Boolean,
    },
    highlighted: {
        default: false,
        type: Boolean,
    },
    id: {
        default: null,
        type: String,
    },
    interactive: {
        default: true,
        type: Boolean,
    },
    label: {
        default: '',
        type: String,
    },
    labelStyle: {
        default: 'truncate',
        type: String,
    },
    modelValue: {
        default: false,
        type: [
            Boolean,
            String,
            Number,
            Array as PropType<(string | number)[]>,
        ] as PropType<boolean | string | number | (string | number)[] | null>,
    },
    name: {
        default: 'checkbox',
        type: String,
    },
    value: {
        default: true,
        type: [Boolean, String, Number],
    },
});

const emit = defineEmits(['update:modelValue']);

const id = props.id ?? useId();
const localValue = ref<boolean | string | number | null | (string | number)[]>(
    props.modelValue,
);

watch(
    () => props.modelValue,
    () => {
        localValue.value = props.modelValue;
    },
);

watch(localValue, () => {
    emit('update:modelValue', localValue.value);
});
</script>

<template>
    <label
        :for="id"
        :class="{
            'pointer-events-none opacity-40': disabled && interactive,
        }"
        class="flex w-fit max-w-full gap-[0.625rem] hover:cursor-pointer"
    >
        <span class="relative mt-1 block w-[1.25rem] min-w-[1.25rem]">
            <input
                :id="id"
                v-model="localValue"
                :value="value"
                :name="name"
                :disabled="disabled"
                :class="{
                    'border-2 border-red-700': error,
                    'border border-gray-300': !error,
                    'ring-2 ring-yellow-600 ring-offset-2': highlighted,
                }"
                class="peer inline-flex h-[1.25rem] w-full cursor-pointer appearance-none items-center justify-center rounded-[4px] bg-white outline-none hover:shadow-md focus:shadow-none focus-visible:ring-2 focus-visible:ring-blue-600 focus-visible:ring-offset-2 active:shadow-none"
                type="checkbox"
            />
            <JamBaseIcon
                class="invisible absolute left-0 inline-flex !h-[1.25rem] !w-[1.25rem] cursor-pointer items-center justify-center rounded-[4px] p-1 text-white peer-checked:visible peer-checked:bg-blue-900"
                icon-name="checkbox"
                size="small"
            />
        </span>

        <span :class="labelStyle">
            <template v-if="label"> {{ label }} </template>
            <slot v-else />
        </span>
    </label>
</template>
